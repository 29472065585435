/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import smoothscroll from 'smoothscroll-polyfill';

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  smoothscroll.polyfill();
  window.onload = () => {
    if (!window.location.pathname.includes('confirmation')) {
      addScript("https://www.bugherd.com/sidebarv2.js?apikey=0opf6avtxr8pki4lontomw");
    }
    const classNames = [];
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push('device-ios');
    if (navigator.userAgent.match(/android/i)) classNames.push('device-android');

    const html = document.getElementsByTagName('html')[0];

    if (classNames.length) classNames.push('on-device');
    if (html.classList) html.classList.add.apply(html.classList, classNames);
  }
}